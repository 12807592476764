import { ReactNode, forwardRef } from 'react';
import { ARTWORK_SIZES, ListItem, ListItemLabel, MenuAdapterProps } from 'baseui/list';
import { StatefulMenu } from 'baseui/menu';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { BaseWebHelpers } from '../../helpers';
import { Block } from 'baseui/block';
import { ChevronDown } from 'baseui/icon';
import { PLACEMENT, StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';

export type BeSideNavItemMenuItem = {
  label: string | ReactNode;
  [name: string]: any;
  onClick?: () => void;
};

export interface BeSideNavItem {
  icon?: (any?) => string | ReactNode;
  iconClass?: any;
  label: string;
  description?: string;
  url?: string;
  href?: string;

  menuItems?: BeSideNavItemMenuItem[];

  [name: string]: any;
}

export type BeSideNavProps = {
  isCollapsed?: boolean;
  onItemClick?: (item: any) => void;
  items: BeSideNavItem[];
};

export const BeSideNav = ({ items, onItemClick, isCollapsed }: BeSideNavProps) => {
  return (
    <>
      <StatefulMenu
        items={items}
        {...(onItemClick ? { onItemSelect: ({ item }) => onItemClick(item) } : {})}
        overrides={{
          List: {
            style: {
              height: '100%',
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: '20px',
              ':focus': {
                outline: 'none',
              },
            },
          },
          Option: {
            props: {
              overrides: {
                ListItem: {
                  component: forwardRef((props: any, ref) => {
                    return props.item.url || props.item.href ? (
                      <Link href={props.item.url || props.item.href}>
                        <MenuAdapter
                          {...props}
                          ref={ref}
                          artwork={props.item.icon}
                          artworkSize={ARTWORK_SIZES.LARGE}
                          iconOnly={isCollapsed}
                        >
                          {isCollapsed ? null : (
                            <ListItemLabel
                              description={props.item.description || ''}
                              overrides={{
                                LabelDescription: {
                                  style: ({ $theme }: any) => ({
                                    color: $theme.colors.gray600,
                                  }),
                                },
                              }}
                            >
                              {props.item.label}
                            </ListItemLabel>
                          )}
                        </MenuAdapter>
                      </Link>
                    ) : (
                      <MenuAdapter
                        {...props}
                        ref={ref}
                        artwork={props.item.icon}
                        artworkSize={ARTWORK_SIZES.LARGE}
                        iconOnly={isCollapsed}
                      >
                        {!isCollapsed && (
                          <ListItemLabel
                            description={props.item.description}
                            overrides={{
                              LabelDescription: {
                                style: ({ $theme }: any) => ({
                                  color: $theme.colors.gray600,
                                }),
                              },
                            }}
                          >
                            {props.item.label}
                          </ListItemLabel>
                        )}
                      </MenuAdapter>
                    );
                  }),
                },
              },
            },
          },
        }}
      />
    </>
  );
};

const MenuAdapter = forwardRef<MenuAdapterProps, HTMLLIElement>(({ iconOnly, ...props }: any, ref: any) => {
  const router = useRouter();
  const calculatedListItem = (
    <ListItem
      ref={ref}
      sublist={props.sublist || props.$size === 'compact'}
      artwork={props.artwork}
      artworkSize={props.artworkSize}
      overrides={{
        ArtworkContainer: {
          style: ({ $theme }: any) => ({
            color: [
              props.item.href,
              typeof props.item.url === 'string' ? props.item.url?.split('?')?.[0] : '',
            ].includes(router.route)
              ? $theme.colors.yellow900
              : $theme.colors.blue900,
            width: iconOnly ? '100%' : '64px',
            paddingLeft: iconOnly ? 0 : props.item.label ? '8px' : '6px',
          }),
        },
        Root: {
          props: { onMouseEnter: props.onMouseEnter, onClick: props.onClick },
          style: ({ $theme }: any) => ({
            height: '62px',
            backgroundColor: props.$isHighlighted ? $theme.colors.menuFillHover : null,
            cursor: props.$disabled ? 'not-allowed' : 'pointer',

            // If there are menuItems, add a rounded border to simulate button appearance
            // eslint-disable-next-line
            ...(props.item.menuItems == null
              ? {}
              : {
                  cursor: 'pointer',
                  ...BaseWebHelpers.expandBorderStyles({
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: $theme.colors.borderOpaque,
                  }),
                  ...BaseWebHelpers.padding('4px'),
                  ...BaseWebHelpers.borderRadius('4px'),
                }),
          }),
        },
        Content: {
          style: {
            height: '62px',
            ...(iconOnly
              ? {
                  width: 0,
                  maxWidth: 0,
                  paddingRight: 0,
                  borderBottomSize: 0,
                }
              : {}),
          },
        },
      }}
    >
      {props.children}

      {/* eslint-disable-next-line eqeqeq */}
      {props.item.menuItems == null ? null : (
        <>
          <Block
            overrides={{
              Block: { style: { fontSize: '0.8em', lineHeight: '0.8em' } },
            }}
          >
            Select a dashboard
          </Block>

          <ChevronDown
            size={28}
            title={'Select a dashboard'}
            /* Padding to align with style of BeAvatarMenu chevron */
            overrides={{ Svg: { style: ({ $theme }: any) => ({ paddingLeft: $theme.sizing.scale200 }) } }}
          />
        </>
      )}
    </ListItem>
  );

  return props.item.menuItems && props.item.menuItems.length > 0 ? (
    <StatefulPopover
      returnFocus
      dismissOnClickOutside={true}
      placement={PLACEMENT.bottomLeft}
      popperOptions={{ modifiers: { flip: { enabled: false } } }}
      triggerType={TRIGGER_TYPE.click}
      overrides={{
        Body: {
          style: {
            marginTop: '2px',
          },
        },
        Inner: {
          style: ({ $theme }) => ({
            backgroundColor: $theme.colors.white,
            width: innerWidth,
            ...BaseWebHelpers.borderRadius('8px'),
          }),
        },
      }}
      content={({ close }) => {
        console.log('content clicked!');

        return (
          <Block
            overrides={{
              Block: {
                style: {
                  ...BaseWebHelpers.borderRadius('8px'),
                },
              },
            }}
          >
            <StatefulMenu
              items={props.item.menuItems as any[]}
              onItemSelect={({ item }: { item: BeSideNavItemMenuItem }) => {
                if (item.onClick) {
                  item.onClick();
                }
                close();
              }}
              overrides={{
                List: {
                  style: {
                    ...BaseWebHelpers.borderRadius('8px'),
                    ':focus': {
                      outline: 'none',
                    },
                  },
                },
                ListItem: {
                  style: ({ $theme }) => ({
                    paddingLeft: '22px',
                    fontSize: '12px',
                    fontWeight: 500,
                    color: $theme.colors.contentPrimary,
                    lineHeight: '40px',
                  }),
                },
              }}
            />
          </Block>
        );
      }}
    >
      {/* Block is required to provide a target */}
      <Block>{calculatedListItem}</Block>
    </StatefulPopover>
  ) : (
    calculatedListItem
  );
});

import { PERMISSIONS, SUBSCRIPTIONS } from '@benefeature/shared-common';
import { UserRole } from '@benefeature/shared-types';

export const UNSUBSCRIBED_SEARCH_LIMIT_MAX = 10;

export const DEFAULT_PAGE = 1;
export const DEFAULT_SEARCH_RESULT_LIMIT = 10;
export const DEFAULT_EXPORT_MAX_RESULTS_PER_BATCH = 1000;

export const TOP_EMPLOYERS_DEFAULT_LIMIT = 5;

export const MARKET_PRESENCE_DEFAULT_LIMIT = 5;
export const MARKET_PRESENCE_DETAILED_DEFAULT_LIMIT = 10;
export const MARKET_PRESENCE_MAX_LIMIT = 40;

// Defines profile filters which should have the effect of disabling market presence and competition info
export const DISABLE_MARKET_PRESENCE_FILTERS = ['prior-carrier-name', 'wins-from-name'];

/**
 * Defines how many results a Pro access subscription should be restricted to during exports (BFR-1061)
 */
export const PRO_TOTAL_RESULT_LIMIT = 200;

export const TREND_HISTORY_YEARS_DEFAULT_LOOKBACK = 5;

export const EXCEL_MAX_WORKSHEET_NAME_LENGTH = 30;

export const DEFAULT_ALL_OTHERS_THRESHOLD_RATIO = 0.01;

export const PRIMARY_PRODUCTS_CASE_RATIO_THRESHOLD = 0.02;
export const PRIMARY_PRODUCTS_PREMIUM_RATIO_THRESHOLD = 0.005;

// 7 days
export const NEXTAUTH_SESSION_MAX_AGE_SECS = 7 * 24 * 60 * 60;
// 1 hour
export const NEXTAUTH_SESSION_UPDATE_AGE_SECS = 1 * 60 * 60;
// 3 minutes
export const NEXTAUTH_VERIFICATION_TOKEN_WINDOW_AGE_MS = 180000;
// 3 uses max
export const NEXTAUTH_VERIFICATION_TOKEN_MAX_USES = 3;

// Used to multiply out Stripe's seconds-based epoch dates to millisecond-based dates
export const STRIPE_DATE_CONVERSION_FACTOR = 1000;

export const AUTH_VERIFY_REQUEST_QUERY_PARAM = 'verification-link';

// Used to define the offset in pixels before the top of a given anchor element which triggers a change in the active anchor state
export const DEFAULT_SCROLL_BUFFER_PX = 10;

export const SUBSCRIBED_ACCESS_ROLES: UserRole[] = [
  { hasPermissions: [PERMISSIONS.SYSTEM_ADMIN] },
  { hasSubscriptions: [SUBSCRIPTIONS.ENTERPRISE] },
  { hasSubscriptions: [SUBSCRIPTIONS.PRO] },
];

export const ENTERPRISE_ACCESS_ROLES: UserRole[] = [
  { hasPermissions: [PERMISSIONS.SYSTEM_ADMIN] },
  { hasSubscriptions: [SUBSCRIPTIONS.ENTERPRISE] },
];

export const clamp2LinesBlockOverride = {
  Block: {
    style: {
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      whiteSpace: 'break-spaces',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
};

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const ALL_INSURANCE_PRODUCTS = [
  'LTD',
  'STD',
  'Life',
  'Health',
  'Dental',
  'Vision',
  'Prescription Drug',
  'Stop Loss',
  'AD&D',
  'Accident',
  'Hospital',
  'EAP',
  'Cancer',
  'Critical Illness',
  'Business Travel',
  'Paid Family Leave',
  'Long Term Care',
  'Prepaid Legal',
  'Organ Transplant',
  'Dependent Life',
  'Major Medical',
  'Medical Abroad',
  'Medical Bridge',
];

export type FinancialPlanFeaturesType = {
  column: string;
  description: string;
  code: string;
};

export const ALL_FINANCIAL_PLAN_FEATURES: FinancialPlanFeaturesType[] = [
  {
    code: '2A',
    description: 'Age Service Weighted Plan',
    column: 'age-service-weighted-plan-2a',
  },
  {
    code: '2B',
    description: 'Target Benefit Plan',
    column: 'target-benefit-plan-2b',
  },
  {
    code: '2C',
    description: 'Money Purchase Plan',
    column: 'money-purchase-plan-2c',
  },
  {
    code: '2D',
    description: 'Offset Plan',
    column: 'offset-plan-2d',
  },
  {
    code: '2E',
    description: 'Profit Sharing Plan',
    column: 'profit-sharing-plan-2e',
  },
  {
    code: '2F',
    description: 'ERISA 404(c) Plan',
    column: 'erisa-404c-plan-2f',
  },
  {
    code: '2G',
    description: 'Total Participant Directed Plan',
    column: 'total-participant-directed-plan-2g',
  },
  {
    code: '2H',
    description: 'Partial Participant Directed Plan',
    column: 'partial-participant-directed-plan-2h',
  },
  {
    code: '2I',
    description: 'Stock Bonus Plan',
    column: 'stock-bonus-plan-2i',
  },
  {
    code: '2J',
    description: '401(k) Plan',
    column: 'retirement-401k-plan-2j',
  },
  {
    code: '2K',
    description: '401(m) Plan',
    column: 'retirement-401m-plan-2k',
  },
  {
    code: '2L',
    description: 'Annuity Contract 501(c) Plan',
    column: 'annuity-contract-501c-plan-2l',
  },
  {
    code: '2M',
    description: 'Custodial Account Plan',
    column: 'custodial-account-plan-2m',
  },
  {
    code: '2N',
    description: 'Code 408 Plan',
    column: 'code-408-plan-2n',
  },
  {
    code: '2O',
    description: 'ESOP Non-Leveraged Plan',
    column: 'esop-non-leveraged-plan-2o',
  },
  {
    code: '2P',
    description: 'ESOP Leveraged Plan',
    column: 'esop-leveraged-plan-2p',
  },
  {
    code: '2Q',
    description: 'S-Corp ESOP Plan',
    column: 's-corp-esop-plan-2q',
  },
  {
    code: '2R',
    description: 'Participant Directed Brokerage Acct Plan',
    column: 'participant-directed-brokerage-acct-plan-2r',
  },
  {
    code: '2S',
    description: 'Automatic Enrollment Plan',
    column: 'automatic-enrollment-401k-403b-plan-2s',
  },
  {
    code: '2T',
    description: 'Participant Directed Default Investment Plan',
    column: 'default-investment-plan-2t',
  },
  {
    code: '3B',
    description: 'Self Employed Retirement Plan',
    column: 'self-employed-retirement-plan-3b',
  },
  {
    code: '3C',
    description: 'Non Qualified Plan',
    column: 'non-qualified-plan-3c',
  },
  {
    code: '3D',
    description: 'Pre Approved Pension Plan',
    column: 'pre-approved-pension-plan-3d',
  },
  {
    code: '3F',
    description: 'Received Services Of Leased Employees',
    column: 'received-services-of-leased-employees-3f',
  },
  {
    code: '3H',
    description: 'Member Of Controlled Group',
    column: 'member-of-controlled-group-3h',
  },
  {
    code: '3I',
    description: 'Employer Securities Plan',
    column: 'employer-securities-plan-3i',
  },
  {
    code: '3J',
    description: 'Puerto Rico 401 1165 Plan',
    column: 'puerto-rico-401-1165-plan-3j',
  },
];
